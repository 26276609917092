// We use this file as an entrypoint for Webpack.

// By requiring context we specify that all files
// in [folder] should be required and bundled
// The require.context exports an API with functions to
// resolve the individual modules

// https://github.com/webpack/docs/wiki/context

function requireAll (r) {
    r.keys().forEach(r);
}

// The Script Loader plugin executes scripts once in global context
// This is great for legacy scripts
// https://github.com/webpack-contrib/script-loader

require('core-js/es6/promise');

requireAll(require.context('script-loader!./Libraries/', true, /\.js$/));

requireAll(require.context('script-loader!./Plugins/', true, /\.js$/));

requireAll(require.context('script-loader!./Components/', true, /\.js$/));

requireAll(require.context('script-loader!./Mocks/', true, /\.js$/));




